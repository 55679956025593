import Slider from './Sliders'
import uniqid from 'uniqid'

export default class ProductDetails {
    constructor() {
        this.productRefTodd = document.querySelector(`.product-ref-todd`).value
        this.tabs = document.querySelectorAll(`.tab-product-details`)
        this.buttonsTabs = document.querySelectorAll(`.button-tab-product-details`)
        this.matomoViewProductDone = false
        
        for (let button of this.buttonsTabs) {
            button.addEventListener("click", () => {
                this.disableButtonsHighlight()
                this.hideTabs()
                button.classList.add("active")
                let id = button.getAttribute('id').replace("button-", "").replace("-link", "-area")
                let tab = document.getElementById(id)
                tab.classList.remove("d-none")
            })
        }
        
        const THIS = this
        if (document.readyState == "complete") {
            THIS.resetSliders()
        } else {
            document.addEventListener("readystatechange", function() {
                switch (document.readyState) {
                    case "complete":
                        THIS.resetSliders()
                        break
                }
            })
        }
        
        if (window.SESSION['isSessionRequestEnd'] == true) {
            setTimeout(() => {
                THIS.matomoViewProduct()
            }, 1000)
        } else {
            document.addEventListener("session.request.end", function() {
                setTimeout(() => {
                    THIS.matomoViewProduct()
                }, 1000)
            })
        }
        
        if (window.SESSION['isAddToCartCreateAllEnd'] == true) {
            setTimeout(() => {
                THIS.matomoViewProduct()
            }, 1000)
        } else {
            document.addEventListener("addtocart.create.all.end", function() {
                setTimeout(() => {
                    THIS.matomoViewProduct()
                }, 1000)
            })
        }
    }

    resetSliders() {
        const sliders = [...document.querySelectorAll(`.js-slider-product-details`)]
        sliders.forEach(slide => {
            slide.id = `slider-${uniqid()}`
            const slider = new Slider(slide.id, {
                slideBy : 'page',
                controls : false,
                autoplayButtonOutput : false,
            })
        })
    }
    
    disableButtonsHighlight() {
        for (let button of this.buttonsTabs) {
            button.classList.remove("active")
        }
    }
    
    hideTabs() {
        for (let tab of this.tabs) {
            tab.classList.add("d-none")
        }
    }
    
    matomoViewProduct() {
        if (this.matomoViewProductDone == false) {
            let matomo = window.matomos.findMatomo(this.productRefTodd)
            if (matomo != null) {
                this.matomoViewProductDone = true
                matomo.viewProduct()
            }
        }
    }
}
