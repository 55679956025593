import axios from 'axios'
import Cookies from 'js-cookie'
import { camelCase } from 'change-case'

const ntype = document.querySelector(`#ntype`)
const nid = document.querySelector(`#nid`)
const getRefs = () => {
    let refs = ``
    const elements = [...document.querySelectorAll(`.price-ref`)]
    elements.forEach(element => {
        if (element.nodeName === `INPUT`) {
            if (element.value) {
                refs = `${refs},${element.value}`
            }
        }
    })

    return refs
}

window.SESSION = {
    cartItemsCount : 0,
    cartResume : null,
    accountResume : null,
    csrfmiddlewaretoken : Cookies.get(`csrftoken`),
    isSessionRequestEnd : false,
    isAddToCartCreateAllEnd : false,
    href : window.location.href,
    ntype : ntype?.value || null,
    nid : nid?.value || null,
    refs : getRefs(),
}

const changeEvent = (key, value) => {
    return new CustomEvent(`${key}.changed`, {
        detail : {value},
    })
}

export default {
    formData(data) {
        const formData = new FormData()
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value)
        })
        return formData
    },
    get(item = false) {
        if (item) {
            return window.SESSION[item]
        }
        return window.SESSION
    },
    setItem(item, value) {
        const key = camelCase(item)
        window.SESSION[key] = value
        document.dispatchEvent(changeEvent(key, value))
    },
    async request() {
        const { csrfmiddlewaretoken, href, ntype, nid, refs } = this.get()
        const response = await axios.post(`/mon-compte/session/`, this.formData({
            csrfmiddlewaretoken,
            href,
            ntype,
            nid,
            refs,
        }))
        Object.entries(response.data).forEach(([key, value]) => {
            this.setItem(key, value)
        })
        window.SESSION["isSessionRequestEnd"] = true
        document.dispatchEvent(new Event("session.request.end"))
    }
}
