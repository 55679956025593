import axios from 'axios'
import Slider from './Sliders'
import AddToCart from './AddToCart'
import uniqid from 'uniqid'

class Preview {
    constructor(wrapper, listName) {
        this.wrapper = wrapper
        this.container = document.querySelector('.js-ProductPreview_Container')
        this.button = document.querySelector('.js-ProductPreview_Button')
        this.user = wrapper.getAttribute('data-user')
        this.product = wrapper.getAttribute('data-product')
        this.view = wrapper.getAttribute('data-view')
        this.listName = listName
        this.element = document.createElement('div')
        this.element.classList.add(`js-ProductPreviews-${this.listName}`)
        this.htmlContent = null
        this.url = `/catalogue/preview-${this.listName}/`
        if (this.product) {
            this.url = `/catalogue/preview-product_associations/${this.listName}/${this.product}/`
        }
        if (this.view) {
            this.url = `${this.url}${this.view}/`
        }
        this.storageItem = `${this.user}${this.url}`
        this.init()
    }

    resetSliders() {
        const slidersContainers = [...this.element.querySelectorAll(`.js-slider-container`)]
        slidersContainers.forEach(container => {
            container.classList.remove("d-none");
        })
        const sliders = [...this.element.querySelectorAll(`.js-slider`)]
        sliders.forEach(slide => {
            slide.id = `js-ProductPreviews-Slider-${this.listName}`
            const slider = new Slider(slide.id, {
                slideBy : 'page',
                controls : false,
                autoplayButtonOutput : false,
            })
        })
    }

    resetAddToCart() {
        const addToCartForms = [...this.element.querySelectorAll(`form.add_to_cart`)]
        addToCartForms.forEach(form => {
            new AddToCart(form)
        })
        window.SESSION['isAddToCartCreateAllEnd'] = true
        document.dispatchEvent(new Event('addtocart.create.all.end'))
    }

    appendContent(resetSliders=false) {
        this.wrapper.innerHTML = ''
        this.element.innerHTML = this.htmlContent
        this.wrapper.appendChild(this.element)
        if (this.htmlContent.trim() !== '') {
            if (this.container && this.button) {
                this.container.classList.remove('d-none')
                this.button.classList.remove('d-none')
            }
        }
    }

    async fetchHtmlContent() {
        try {
            if (!this.product && window.localStorage.getItem(this.storageItem)) {
                this.htmlContent = window.localStorage.getItem(this.storageItem)
                this.appendContent()
            }
            await axios.get(`${window.location.origin}${this.url}`).then(response => {
                this.htmlContent = response.data
                if (!this.product) {
                    window.localStorage.setItem(this.storageItem, response.data)
                }
                this.appendContent()
                if (!this.product) {
                    this.resetSliders()
                } else {
                    this.resetAddToCart()
                }
                document.dispatchEvent(new Event("productpreviews.create.all.end"))
            })
        } catch(error) {
            console.log(error)
        }
    }

    init() {
        this.fetchHtmlContent()
    }
}

export default class ProductPreviews {
    constructor(element) {
        this.element = element
        this.lists = JSON.parse(this.element.getAttribute('data-lists'))
        this.init()
    }

    init() {
        this.lists.forEach(listName => {
            new Preview(this.element, listName)
        })
    }
}
