import axios from 'axios'
import Slider from './Sliders'
import AddToCart from './AddToCart'
import uniqid from 'uniqid'

class Preview {
    constructor(wrapper, listName) {
        this.wrapper = wrapper
        this.container = document.querySelector('.js-ProductPreview_Container')
        this.button = document.querySelector('.js-ProductPreview_Button')
        this.user = wrapper.getAttribute('data-user')
        this.product = wrapper.getAttribute('data-product')
        this.listName = listName
        this.element = document.createElement('div')
        this.element.classList.add(`js-ProductPreviews-${this.listName}`)
        this.htmlContent = null
        this.url = `/catalogue/preview-${this.listName}/`
        if (this.product) {
            this.url = `/catalogue/preview-product_associations/${this.listName}/${this.product}/`
        }
        this.storageItem = `${this.user}${this.url}`
        this.init()
    }

    resetSliders() {
        const slidersContainers = [...this.element.querySelectorAll(`.js-slider-container`)]
        slidersContainers.forEach(container => {
            container.classList.remove("d-none");
        })
        const sliders = [...this.element.querySelectorAll(`.js-slider`)]
        sliders.forEach(slide => {
            slide.id = `js-ProductPreviews-Slider-${this.listName}`
            const slider = new Slider(slide.id, {
                slideBy : 'page',
                controls : false,
                autoplayButtonOutput : false,
            })
        })
    }

    resetAddToCart() {
        const addToCartForms = [...this.element.querySelectorAll(`form.add_to_cart`)]
        addToCartForms.forEach(form => {
            new AddToCart(form)
        })
        window.SESSION['isAddToCartCreateAllEnd'] = true
        document.dispatchEvent(new Event('addtocart.create.all.end'))
    }

    appendContent(resetSliders=false) {
        this.element.innerHTML = this.htmlContent
        this.wrapper.appendChild(this.element)
        if (this.htmlContent.trim() !== '') {
            if (this.container && this.button) {
                this.container.classList.remove('d-none')
                this.button.classList.remove('d-none')
            }
        }
    }

    init() {
        this.fetchHtmlContent()
    }
}

export default class ProductReviews {
    constructor(trigger) {
        this.trigger = trigger
        this.wrapper = document.querySelector('.js-Product__Reviews')
        this.productId = trigger.getAttribute('data-product-id')
        this.url = `${window.location.origin}/catalogue/product-reviews/${this.productId}/`
        this.htmlContent = null
        this.init()
    }

    appendContent() {
        this.wrapper.innerHTML = this.htmlContent
        this.trigger.classList.add('d-none')
    }

    async fetchHtmlContent() {
        try {
            await axios.get(this.url).then(response => {
                this.htmlContent = response.data
                this.appendContent()
            })
        } catch(error) {
            console.log(error)
        }
    }

    onClick(e) {
        this.fetchHtmlContent()
    }

    init() {
        this.trigger.addEventListener('click', e => {
            this.onClick(e)
        })
    }
}
